import * as React from "react"
import { Link } from "gatsby"
import Seo from '../component/Seo'
import '../styles/404.scss'

const NotFoundPage = () => {
  return (
    <div class="content">
      <Seo title="Страница не найдена" />
      <Link to="/">Перейти к главной странице</Link>.
    </div>
  )
}

export default NotFoundPage
